import React from "react"
import Layout from "../components/layout"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
import { graphql, Link } from "gatsby"

const Termsandconditions = () => {
  return (
    <Layout>
      <div className="bg-gradient-to-b  from-black/50  py-10 "></div>
      <section>
        <div className="container px-4 md:px-20 mx-auto  ">
          <Link to="/.">
            <div className="mt-20 flex ite gap-5">
              <ArrowLeftIcon className="w-5 mb-20" />

              <div className="">Back to home</div>
            </div>
          </Link>
          <div className=" font-made-bold text-4xl md:text-6xl mb-8 md:mb-14 ">
            Terms and Conditions
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Welcome to the Watawala Tea website. The following terms and
              conditions, along with our privacy policy, govern your access to
              and use of our website. By accessing or using our website, you
              acknowledge that you have read, understood, and agree to be bound
              by these terms and conditions.
            </div>
          </div>
          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Privacy
          </div>
          <div className="mb-8">
            <div>
              {" "}
              At Watawala Tea, we are committed to protecting your privacy.
              Please refer to our Privacy Policy for detailed information on how
              we collect, use, and protect your personal information.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Content and Intellectual Property
          </div>
          <div className="mb-8">
            <div>
              All content included on the Watawala Tea website, such as text,
              graphics, logos, images, and audio clips, is the property of
              Watawala Tea or its content suppliers and is protected by
              international copyright laws. You may not reproduce, distribute,
              modify, or create derivative works from any content on our website
              without our express written consent.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            License and Access
          </div>
          <div className="mb-8">
            <div>
              Watawala Tea grants you a limited, non-exclusive, non-transferable
              license to access and use our website for personal and
              non-commercial purposes. This license does not include the right
              to resell or commercially use any content or products from our
              website. All rights not expressly granted to you are reserved by
              Watawala Tea.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Disclaimer of Warranties and Limitation of Liability
          </div>
          <div className="mb-8">
            <div>
              The Watawala Tea website is provided on an "as is" basis. We make
              no representations or warranties of any kind, expressed or
              implied, regarding the accuracy, completeness, reliability, or
              availability of our website. You agree that your use of our
              website is at your own risk and that Watawala Tea shall not be
              liable for any direct, indirect, incidental, special, or
              consequential damages resulting from your use of or inability to
              use our website.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Links to Third-Party Sites:
          </div>
          <div className="mb-8">
            <div>
              {" "}
              Our website may contain links to third-party websites for your
              convenience. We do not endorse or control the content, policies,
              or practices of these websites, and we are not responsible for any
              loss or damage that may arise from your use of them. We encourage
              you to review the terms and conditions and privacy policies of any
              third-party websites you visit.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Changes to Terms and Conditions
          </div>
          <div className="mb-8">
            <div>
              {" "}
              We reserve the right to modify or update these terms and
              conditions at any time without prior notice. It is your
              responsibility to review these terms and conditions periodically
              for any changes. Your continued use of our website after any
              modifications or updates constitutes your acceptance of the
              revised terms and conditions.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Termination
          </div>
          <div className="mb-8">
            <div>
              {" "}
              We may terminate or suspend your access to our website at any time
              without prior notice or liability for any reason, including,
              without limitation, if you breach these terms and conditions.
            </div>
          </div>

          <div className="mb-4 md:mb-8 text-xl md:text-2xl font-made-semibold">
            Governing Law and Jurisdiction
          </div>
          <div className="mb-8">
            <div>
              {" "}
              These terms and conditions shall be governed by and construed in
              accordance with the laws of Sri Lanka. Any disputes arising out of
              or in connection with these terms and conditions shall be subject
              to the exclusive jurisdiction of the courts of Sri Lanka.
            </div>
          </div>

          <div className="mb-8 ">
            <div> Contact Information </div>
            <div className="">
              {" "}
              If you have any questions or concerns about these terms and
              conditions or our website, please contact us at
              watawala@sunshineholdings.lk or by phone at +94 011 4702 400.
            </div>
          </div>
          <div className="mb-8 ">
            <div> Effective Date</div>
            <div className=" ">
              {" "}
              These terms and conditions are effective as of 23rd June 2023.
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Termsandconditions
